<template>



<section id="app" class="section">
<div>
    <v-card>
        <v-card-text>

    <div>
    <v-toolbar-title>{{form.formName}}</v-toolbar-title>

   <br>

                <div class="field">
                    <label class="label">База данных</label>
                    <div class="control">
                        
                        <div class="select elevation-2">
                            <select v-model="form.db">
                                <option v-for="color in ['department', 'film', 'car', 'cinema', 'employee', 'library', 'transportation',]" :value="color">
                                    {{color}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

<br>

                <div class="field">
                    <label class="label">Сдвиг (в днях)</label>
                    <div class="control">
                        <input class="input elevation-2" type="text" v-model="form.shift" />
                    </div>
                </div>

                <br>
    
    <v-btn
              color="primary"
              dark
              small
              @click.prevent="fakeSubmit"
            >
              Применить
    </v-btn>
</div>
</v-card-text>
</v-card>
</div>        

<br>

<div>
    <v-card>
        <v-card-text>

    <div>
    <v-toolbar-title>{{form.formName}}</v-toolbar-title>

   <br>

                <div class="field">
                    <label class="label">База данных</label>
                    <div class="control">
                        
                        <div class="select elevation-2">
                            <select v-model="form1.db">
                                <option v-for="color in ['department', 'film', 'car', 'cinema', 'employee', 'library', 'transportation',]" :value="color">
                                    {{color}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

<br>

                <div class="field">
                    <label class="label">Сдвиг (в днях)</label>
                    <div class="control">
                        <input class="input elevation-2" type="text" v-model="form1.shift" />
                    </div>
                </div>

                <br>
    
                <div class="field">
                    <label class="label">Таблица</label>
                    <div class="control">
                        <input class="input elevation-2" type="text" v-model="form1.table" />
                    </div>
                </div>

                <br>

                <div class="field">
                    <label class="label">Поле</label>
                    <div class="control">
                        <input class="input elevation-2" type="text" v-model="form1.column" />
                    </div>
                </div>

                <br>

    <v-btn
              color="primary"
              dark
              small
              @click.prevent="shiftcolumn"
            >
              Применить
    </v-btn>
</div>
</v-card-text>
</v-card>
</div>        


</section>

</template>

<script>
  export default {
    data: () => ({
        form: {
            formName: "Смещение по времени",
            shift: "",
            db: "department",
        },

        form1:{
            formName: "Смещение по времени",
            shift: "",
            db: "department",
            column: "",
            table: "",
        },

        showSubmitFeedback: false
    }),




    methods: {
        async fakeSubmit() {
            await this.$store.dispatch("ShiftDatabase", {db: this.form.db, shift: this.form.shift});
        },

        async shiftcolumn() {
            await this.$store.dispatch("ShiftColumn", {db: this.form1.db, shift: this.form1.shift, table: this.form1.table, column: this.form1.column});
        }
    }
    }
</script>
