import mutations from './mutations'
import { parseJwt} from "./func"
import authModule from "./modules/authModule";
import teacherModule from "./modules/teacherModule";
import studentModule from "./modules/studentModule";
import router from '../router'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';
import Swal from "sweetalert2";

import axios from 'axios'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
})


export default new Vuex.Store({
  state: {
    jwt: null,
    isDark:false,
    port: "http://awesomesql.ru/api/", 
    user:{
      user_id:null,
      name:null,
      email:null,
      is_teacher:null,
      avatar: null,
    },
    solutions: [],
    layoutWorks:[],
    layoutWorkInfo:null,
    databases: [],
    databaseInfo: null,
    works: [],
    tests: [],
    workInfo: null,
    studentWorkInfo: null,
    groups: [],
    students:[],
    tasks:[],
    archivedworks:[],
    taskswithresults:[]
  },
  mutations,
  modules: {
    authModule,
    teacherModule,
    studentModule
  },
  actions: {

    Exit({commit}){
        this.commit("setUser", {name:null})
        this.commit("setJwt", null)
        this.commit("setLayoutWorks", [])
        this.commit("setLayoutWorkInfo", null)
        this.commit("setDatabases", [])
        this.commit("setWorks", [])
        this.commit("setWorkInfo", null)
        this.commit("setStudentWorkInfo", null)
        this.commit("setGroups", [])
        this.commit("setDatabaseInfo", null)
        this.commit("setTasks", [])
        this.commit("setSolutions", [])
    },

  },
  plugins: [vuexLocalStorage.plugin]
})
